import React from "react";
import {useSetting} from "../../contexts/setting";
import cn from "classnames";
import './index.css';
import {UI} from "../../data/constants";
import {ICONS} from "../../data/constants";



const Button = ({value, handleClick, src, isCurrent}) => {
  const btnClass = cn('icon-button', { "selected": isCurrent });
  return (
    <button
      value={value}
      className={btnClass}
      onClick={() => handleClick(value)}
      style={{backgroundImage: `url(${src})`}}
    ></button>
  )
}


const IconSelector = ({ amount }) => {
  const {iconOption, editIconOption, channel } = useSetting();

  const getIconsList = () => {
    if (channel.value === 'email') {
      return ['9', '10', '11', '13'];
    }
    return ICONS;
  }

  const icons = getIconsList();
  const getSrc = value =>  (`${UI}/Icons/${value}.svg`);

  const onBgButtonClick = value => {
    editIconOption(value)
  }


  return (
    <div className="icon-selector">
      {icons.slice(0, amount).map((icon, i) => (
        <Button
          key={icon}
          value={icon}
          src={getSrc(icon)}
          handleClick={onBgButtonClick}
          isCurrent={iconOption === icon}
        />
      ))}
    </div>
  )}

export default IconSelector;
