import React from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import {UI, newsletterLayoutTypes} from "../../../data/constants";
import {getLogoUrl} from "../../../libs/tools";
import { checkIfIDE } from "../../../libs/tools";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const FacebookSocial = ({ id, zoom, }) => {
  const { product, layoutOption, iconOption, type } = useSetting();
  const { titles, subtitles, dates } = useEdits();
  const { bgUrl } = useImages();
  const logoURL = getLogoUrl(product.value, bgUrl, type.value);
  const title = titles[id] || titles.main;
  const date = dates[id] || dates.main;
  const subtitle = subtitles[id] || subtitles.main;
  const iconUrl = `${UI}/Icons/${iconOption}.svg`;
  const isNewsletter = newsletterLayoutTypes.includes(type.value);
  const newsletterClass = isNewsletter ? "newsletter" : "";
  const templateClass = cn("template", `layout-${layoutOption}`, newsletterClass);
  const logoClass = cn("logo", {"ide": checkIfIDE(product.value, type.value)});

  return (
    <>

      <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content" >
            <header className="template-header">
              <div className={logoClass}>
                <img src={logoURL} alt="background"/>
              </div>
                { product.value === "JB" || product.value === "EDU" ? "" : (
                <div className="product-name">
                  {product.label}
                </div>
              )}
            </header>

            <div className="title-wrap">
              <h1 className="title">
                <Textarea id={id} text={title} />
              </h1>
              {isNewsletter && (
                <div className="date">
                  <Textarea id={id} text={date} inline={true}/>
                </div>
              )}
              {[3, 4].includes(layoutOption) && (
                <h2 className="subtitle">
                  <Textarea id={id} text={subtitle} />
                </h2>
              )}
            </div>
          </div>
          {+layoutOption === 7 && (<div className="icon-wrap">
            <Icon bgUrl={iconUrl}/>
          </div>)}
          {[5, 6].includes(+layoutOption) && (<div className="upload-wrap">
            <ImageUpload id={id}/>
          </div>)}
        </div>
      </div>
    </>
  );
}

export default FacebookSocial;
