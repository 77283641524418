import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import UndoIcon from "../UndoIcon";
import {useEffect, useState, useRef} from "react";
import cn from "classnames";
import {useImages} from "../../contexts/images";
import {useSetting} from "../../contexts/setting";
import './index.css';
import {IMG} from "../../data/constants";

const BgTransformer = ({ src, id }) => {
  const setTransformRef = useRef(null);
  const {isBgToolsExpanded, editIsBgToolsExpanded,} = useSetting();
  const {bgX, editBgX, bgY, editBgY, bgScale, editBgScale, renderKey} = useImages();
  const buttonPack = cn("button-pack", {"visible": isBgToolsExpanded});
  const expandBtn = cn("round-btn expander", {"opaque": isBgToolsExpanded});
  const imgClass = cn("image-wrapper", {"active": isBgToolsExpanded});

  // set background position when loading a saved scene
  useEffect(() => {
    if (setTransformRef.current) {
      if (bgX[id]) {
        setTransformRef.current(bgX[id], bgY[id], bgScale[id]);
      } else {
        setTransformRef.current(0, 0, 1);
      }
    }
  }, [renderKey]);



  const handleTransform = value => {
    editBgScale(id, value.state.scale);
    editBgX(id, value.state.positionX);
    editBgY(id, value.state.positionY);
  }

  const expand = () => {
    editIsBgToolsExpanded(!isBgToolsExpanded);
  }

  return (
    <div className="bg-transformer">
      <TransformWrapper
        initialScale={bgScale[id]}
        minScale={0.1}
        initialPositionX={bgX[id]}
        initialPositionY={bgY[id]}
        wheel={{ step: 0.01 }}
        centerZoomedOut={false}
        centerOnInit={false}
        limitToBounds={false}
        onTransformed={handleTransform}
      >
        {({ zoomIn, zoomOut, resetTransform, setTransform, ...rest }) => {
          setTransformRef.current = setTransform;

          return (
            <>
              <TransformComponent wrapperClass={imgClass}>
                <img src={src} alt="background" />
              </TransformComponent>

              <div className="tools">
                <button title="Edit background" className={expandBtn} onClick={() => expand()}><img src={IMG}/></button>
                <div className={buttonPack}>
                  <button className="round-btn" onClick={() => zoomIn()}>+</button>
                  <button className="round-btn" onClick={() => zoomOut()}>−</button>
                  <button className="round-btn" onClick={() => resetTransform()}>
                    <UndoIcon />
                  </button>
                </div>
              </div>
            </>
          );
        }}
      </TransformWrapper>
    </div>
  )}

export default BgTransformer;