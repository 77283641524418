import Templates from './layout/Templates';
import Toolbar from './layout/Toolbar';
import '@rescui/colors/lib/index.css';
import './App.css';
import './css/fonts.css';
import { useEffect, useState } from "react";
import { ThemeProvider } from '@rescui/ui-contexts';
import { API } from './api.js';
import {CHANNELS, findChannelObj, GENERAL_TYPE, PRODUCTS} from './data/products.js';
import {DEFAULT_PRODUCT} from './data/constants.js';
import {exportLayouts, findBgUrl, getProductHash} from "./libs/tools";
import {useLocation, useNavigate} from "react-router-dom";
import {useEdits} from './contexts/edits.js';
import {useImages} from "./contexts/images";
import {useSetting} from "./contexts/setting";
import {templates} from "./data/templates";
import {TAGS} from "./data/constants";
import {DEFAULT_BACKGROUND_DATA} from "./data/backgrounds";


const App = () => {
  const { loadTitles, loadSubtitles, editFontVariation, dates, names, positions, titles, subtitles, productNames, fontVariation, loadPositions, loadNames, loadBadges, loadDates, badges} = useEdits();
  const { bgX, bgY, bgScale, uploadX, uploadY, uploadScale, editTransform, rerenderBg, customBg, editBgUrl, editCustomBg, images, loadImages, editUploadTransform, allBgUrls, editBgUrl2x, editBgUrlMini, editProductHash, productHash, editProductTransforms, bgData } = useImages();
  const {editProduct, editType, editChannel, editBgOption, editPalettes, bgOption, product, channel, type, layoutOption, editLayoutOption, editIconOption, addVisible, editTag, editHash, loadLocked, loadVisible, visible, locked, editLayoutAmount, editIsExportLoading, isExportLoading } = useSetting();
  const location = useLocation();
  const navigate = useNavigate();
  const theme = 'dark';
  const [classNames, setClassNames] = useState('');

  // TODO refactor
  const loadNormBg = () => {
    if (allBgUrls.length > 0) {
      const src = findBgUrl(allBgUrls, +bgOption, `/${product.value}`);
      const prodHash = getProductHash(src);
      // const src2x = findBgUrl(allBgUrls, +bgOption, `/${product.value}@2x`);
      const srcMini = findBgUrl(allBgUrls, +bgOption, `/${product.value}-mini`);
      editBgUrl(src);
      editProductHash(prodHash);
      // editBgUrl2x(src2x);
      editBgUrlMini(srcMini);
      editTag(TAGS[2]);

      let newClassNames = '';
      if (src) {
        if (src.includes('_blacklogo')) {
          newClassNames += ' blacklogo';
        }
        if (src.includes('_blacktext')) {
          newClassNames += ' blacktext';
        }
        if (src.includes('_productlogo')) {
          newClassNames += ' productlogo';
        }
        if (src.includes('_whitebg')) {
          newClassNames += ' whitebg';
        }
        if (src.includes('_whitelogo')) {
          newClassNames += ' whitelogo';
        }
        if (src.includes('_graybg')) {
          newClassNames += ' graybg';
        }
        if (src.includes('_blackproduct')) {
          newClassNames += ' blackproduct';
        }
        if (src.includes('_bottomlogoinverse')) {
          newClassNames += ' bottomlogoinverse';
        }

      }
      newClassNames += ` ${product.value}`;
      // Add class for active background
      if (bgOption !== 'custom') {
        newClassNames += ` bg-${bgOption}`;
      }
      setClassNames(newClassNames);

      if (!src) {
        const outdated = findBgUrl(allBgUrls, +bgOption, `/${product.value}-outdated`);
        // const outdated2x = findBgUrl(allBgUrls, +bgOption, `/${product.value}@2x-outdated`);
        const outdatedMini = findBgUrl(allBgUrls, +bgOption, `/${product.value}-mini-outdated`);
        editBgUrl(outdated);
        // editBgUrl2x(outdated2x);
        editBgUrlMini(outdatedMini);

        const isOutdated = PRODUCTS.find(elem =>  elem.value === product.value).tag === "outdated";
        if (isOutdated) {
          editTag(TAGS[1]);
        } else {
          editTag(TAGS[2]);
        }

        if (!outdated) {
          editTag(TAGS[0]);
        }
      }
    }
  }




  useEffect(() => {
    // console.log("bgOption",bgOption)
    if (bgOption === "custom") {
      editBgUrl(customBg);
    } else {
      loadNormBg();
    }
  }, [product, bgOption, customBg, allBgUrls]);

  useEffect(() => {
    console.log('getting product hash transforms', productHash)
    const fetchTransforms =  async () => {
      const transforms = await API.getTransforms(productHash);
      // console.log('EDIT TRANSFORM')
      editTransform(transforms.x, transforms.y, transforms.scale);
      rerenderBg();
    }

    if (productHash && !window.location.hash) {
      fetchTransforms();
    } else if (!window.location.hash) {
      const { x, y, scale } = bgData[bgOption] || DEFAULT_BACKGROUND_DATA ;
      // console.log('EDIT TRANSFORM')
      editTransform(x, y, scale);
      // rerenderBg();
    } else return;

  }, [productHash]);


  useEffect( () => {
    const fetchParams = async () => {
      if (window.location.hash) {
        const h = window.location.hash.slice(1)
        const params = await API.getParams(h);

        if (params) {
          const { product, fontVariation, channel, type, names, dates, titles, subtitles, positions, bgScale, bgX, bgY, bgOption, customBg, layoutOption, iconOption, images, uploadY, uploadX, uploadScale, locked, visible, badges }  = params;

          editHash(h);
          if (product) editProduct(product);
          if (channel) editChannel(channel);
          if (type) editType(type);
          if (bgOption) editBgOption(bgOption);
          if (layoutOption) editLayoutOption(+layoutOption);
          if (images) loadImages(images);
          if (iconOption) editIconOption(+iconOption);
          if (customBg) editCustomBg(customBg);
          if (titles) loadTitles(titles);
          if (subtitles) loadSubtitles(subtitles);
          if (names) loadNames(names);
          if (positions) loadPositions(positions);
          if (badges) loadBadges(badges);
          // if (productNames) editProductNames(productNames);
          if (dates) loadDates(dates);
          if (locked) loadLocked(locked);
          if (visible) loadVisible(visible);
          if (fontVariation) editFontVariation(fontVariation);
          if (bgY || bgX || bgScale) {
            console.log('hash transforms found', bgX, bgY, bgScale)
            // console.log('EDIT TRANSFORM')
            editTransform(bgX, bgY, bgScale);
            rerenderBg();
          }

          if (uploadY || uploadX || uploadScale) {
            editUploadTransform(uploadX, uploadY, uploadScale);
            rerenderBg();
          }
        }
      }
    }

    fetchParams();
  }, [])

  useEffect( () => {
    const getPalettes = async () => {
      try {
        const krakenPalettes = await API.getPalettes();
        editPalettes(krakenPalettes);
      } catch (error) {
        console.error(error);
      }
    }
    getPalettes();
  }, [])



  useEffect( () => {
    templates[channel.value].forEach(t => { addVisible(t.id) });
    const chObj = findChannelObj(channel.value);
    editLayoutAmount(chObj.layoutsNumber);
  }, [channel])



  const exportImages = async () => {
    editIsExportLoading(true);
    const wrappers = document.querySelectorAll(".template-wrap");
    const visible = [...wrappers].filter(w => (!w.classList.contains('hidden')));
    [...visible].forEach(el => el.classList.add("nobg"));
    const nodes = visible.flatMap(element => Array.from(element.querySelectorAll('.template')));
    // const nodes = document.querySelectorAll(".template");
    await exportLayouts([...nodes], product.value, channel.value);
    [...visible].forEach(el => el.classList.remove("nobg"));
    editIsExportLoading(false)
  }

  useEffect(() => {
    if (location.pathname === '/') return;
    if (location.hash) return;

    const pathParts = location.pathname.split('/');
    if (pathParts.length < 1) return;

    const channelValue = pathParts[1];
    const channelObj = findChannelObj(channelValue);

    if (!channelObj) {
      navigate(`/social/${DEFAULT_PRODUCT.value}`);
      return;
    }

    const channelOption = { value: channelValue, label: channelObj.label };
    editChannel(channelOption);

    let productOption;
    if (pathParts.length > 2) {
      const productValue = pathParts[2];
      productOption = PRODUCTS.find(x => x.value === productValue);
    } else {
      const productValue = channelObj.products[0];
      productOption = PRODUCTS.find(x => x.value === productValue);
    }

    if (!productOption) {
      navigate(`/social/${DEFAULT_PRODUCT.value}`);
      return;
    }
    editProduct(productOption);


    const allTypes = channelObj.types;
    if (allTypes.length > 0) {
      const {label, value} = allTypes[0];
      editType({ label, value })
    } else {
      editType(GENERAL_TYPE);
    }
  }, [location]);



  const saveScene = async () => {
    const scene = {
      product,
      channel,
      type,
      visible,
      locked,
      titles,
      dates,
      positions,
      names,
      productNames,
      fontVariation,
      bgX,
      bgY,
      bgScale,
      uploadX,
      uploadY,
      uploadScale,
      bgOption,
      customBg,
      layoutOption,
      images,
      subtitles,
      badges,
    }

    const hash = await API.sendParams(scene);
    const url = `${window.location.origin}${location.pathname}#${hash}`
    navigator.clipboard.writeText(url);
    console.log('saving', hash);
  }


  return (
    <ThemeProvider theme={  theme}>
      <div className={`App${classNames}`}>
        <main>
          <Templates />
          <Toolbar
            handleExport={exportImages}
            handleSaveScene={saveScene}
          />
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;
