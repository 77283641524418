import {useEffect, useState} from "react";
import {templates} from "../../data/templates.js";
import {useEdits} from '../../contexts/edits.js';
import {isEmptyObj} from "../../libs/tools";
import {useSetting} from "../../contexts/setting";
import {PLACEHOLDERS} from "../../data/constants";
import cn from "classnames";
import TemplateControls from "../../layout/TemplateControls";

const LiveStream = () => {
  const { fontOption, visible, product, type } = useSetting();
  const [prevProduct, setPrevProduct] = useState(null);
  const [prevType, setPrevType] = useState(null);
  const { editTitles, titles, subtitles, dates, editDates, editSubtitles} = useEdits();
  const isJBNewsletter = product.value === "JB" && type.value === "newsletter";
  const isDNNewsletter = product.value === "DN" && type.value === "newsletter";
  const isMPNewsletter = product.value === "MP" && type.value === "newsletter";
  const isIJNewsletter = product.value === "IJ" && type.value === "java-annotated";
  const isTCNewsletter = product.value === "TC" && type.value === "newsletter";
  const isPSNewsletter = product.value === "PS" && type.value === "php-annotated";
  const [defaultTitles, setDefaultTitles] = useState(
    isJBNewsletter ? PLACEHOLDERS[fontOption].title_JB_newsletter :
    isDNNewsletter ? PLACEHOLDERS[fontOption].title_DN_newsletter :
    isMPNewsletter ? PLACEHOLDERS[fontOption].title_MP_newsletter :
    isIJNewsletter ? PLACEHOLDERS[fontOption].title_IJ_newsletter :
    isTCNewsletter ? PLACEHOLDERS[fontOption].title_TC_newsletter :
    isPSNewsletter ? PLACEHOLDERS[fontOption].title_PS_newsletter :
    PLACEHOLDERS[fontOption].title
  );
  const [defaultSubtitles, setDefaultSubtitles] = useState(PLACEHOLDERS[fontOption].subtitle);
  const [defaultDates, setDefaultDates] = useState(
    isJBNewsletter ? PLACEHOLDERS[fontOption].date_JB_newsletter :
    isDNNewsletter ? PLACEHOLDERS[fontOption].date_DN_newsletter :
    isMPNewsletter ? PLACEHOLDERS[fontOption].date_MP_newsletter :
    isIJNewsletter ? PLACEHOLDERS[fontOption].date_IJ_newsletter :
    isTCNewsletter ? PLACEHOLDERS[fontOption].date_TC_newsletter :
    isPSNewsletter ? PLACEHOLDERS[fontOption].date_PS_newsletter :
    PLACEHOLDERS[fontOption].date
  );


  useEffect(() => {
    if (isEmptyObj(titles)) {
      editTitles(defaultTitles, "main", false)
    }

    if (isEmptyObj(subtitles)) {
      editSubtitles(defaultSubtitles, "main", false)
    }

    if (isEmptyObj(dates)) {
      editDates(defaultDates, "main", false)
    }
  }, [])

  // Handle product/type changes
  useEffect(() => {
    const newTitles = isJBNewsletter ? PLACEHOLDERS[fontOption].title_JB_newsletter :
                      isDNNewsletter ? PLACEHOLDERS[fontOption].title_DN_newsletter :
                      isMPNewsletter ? PLACEHOLDERS[fontOption].title_MP_newsletter :
                      isIJNewsletter ? PLACEHOLDERS[fontOption].title_IJ_newsletter :
                      isTCNewsletter ? PLACEHOLDERS[fontOption].title_TC_newsletter :
                      isPSNewsletter ? PLACEHOLDERS[fontOption].title_PS_newsletter :
                      PLACEHOLDERS[fontOption].title;
    const newDates = isJBNewsletter ? PLACEHOLDERS[fontOption].date_JB_newsletter :
                     isDNNewsletter ? PLACEHOLDERS[fontOption].date_DN_newsletter :
                     isMPNewsletter ? PLACEHOLDERS[fontOption].date_MP_newsletter :
                     isIJNewsletter ? PLACEHOLDERS[fontOption].date_IJ_newsletter :
                     isTCNewsletter ? PLACEHOLDERS[fontOption].date_TC_newsletter :
                     isPSNewsletter ? PLACEHOLDERS[fontOption].date_PS_newsletter :
                     PLACEHOLDERS[fontOption].date;

    // Only update content if product or type changed
    if (product.value !== prevProduct?.value || type.value !== prevType?.value) {
      editTitles(newTitles, "main", false);
      editDates(newDates, "main", false);
    }

    // Store current product and type for next comparison
    setPrevProduct(product);
    setPrevType(type);
  }, [product, type, isJBNewsletter, isDNNewsletter, isMPNewsletter, isIJNewsletter, isTCNewsletter, isPSNewsletter, fontOption, editTitles, editDates]);

  // Handle default values for empty fields
  useEffect(() => {
    const newTitles = isJBNewsletter ? PLACEHOLDERS[fontOption].title_JB_newsletter :
                      isDNNewsletter ? PLACEHOLDERS[fontOption].title_DN_newsletter :
                      isMPNewsletter ? PLACEHOLDERS[fontOption].title_MP_newsletter :
                      isIJNewsletter ? PLACEHOLDERS[fontOption].title_IJ_newsletter :
                      isTCNewsletter ? PLACEHOLDERS[fontOption].title_TC_newsletter :
                      isPSNewsletter ? PLACEHOLDERS[fontOption].title_PS_newsletter :
                      PLACEHOLDERS[fontOption].title;
    const newDates = isJBNewsletter ? PLACEHOLDERS[fontOption].date_JB_newsletter :
                     isDNNewsletter ? PLACEHOLDERS[fontOption].date_DN_newsletter :
                     isMPNewsletter ? PLACEHOLDERS[fontOption].date_MP_newsletter :
                     isIJNewsletter ? PLACEHOLDERS[fontOption].date_IJ_newsletter :
                     isTCNewsletter ? PLACEHOLDERS[fontOption].date_TC_newsletter :
                     isPSNewsletter ? PLACEHOLDERS[fontOption].date_PS_newsletter :
                     PLACEHOLDERS[fontOption].date;

    setDefaultTitles(newTitles);
    setDefaultDates(newDates);

    // Only update if fields are empty
    if (isEmptyObj(titles)) {
      editTitles(newTitles, "main", false);
    }
    if (isEmptyObj(dates)) {
      editDates(newDates, "main", false);
    }
  }, [fontOption, isJBNewsletter, isDNNewsletter, isMPNewsletter, isIJNewsletter, isTCNewsletter, isPSNewsletter, titles, dates, editTitles, editDates])


  return (
      templates.social.map(item => (
        <div key={item.id} className={cn('template-wrap', { "hidden": !visible.some(x => x === item.id) })}>
          <div className="template-annotation">
            <span className="label">{`${item.label} (${item.dimensions})`}</span>
            <TemplateControls id={item.id} />
          </div>
          {item.getComponent()}
        </div>
      ))
  )
}

export default LiveStream;
