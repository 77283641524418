import React, { useState, useEffect, useRef } from 'react';
import "./Textarea.css";
import cn from "classnames";
import { findParentWithClass } from "../../libs/tools";
import { useSetting } from "../../contexts/setting";
import { useEdits } from "../../contexts/edits";
import { TEXT_CLASSNAMES } from "../../data/constants";

const Textarea = ({ text, id, inline = false }) => {
  const [type, setType] = useState('');
  const { fontOption, locked } = useSetting();
  const { editSubtitles, editPositions, editTitles, editNames, editBadges, editDates, editEaps } = useEdits();
  const labelRef = useRef(null);

  const isLocked = locked.some(elem => elem === id);

  const onTextChange = (e) => {
    console.log(type)
    switch (type) {
      case "title":
        editTitles(e.target.value, id, isLocked);
        break;
      case "subtitle":
        editSubtitles(e.target.value, id, isLocked);
        break;
      case "name":
        editNames(e.target.value, id, isLocked);
        break;
      case "badge":
        editBadges(e.target.value, id, isLocked);
        break;
      case "date":
        editDates(e.target.value, id, isLocked);
        break;
      case "position":
        editPositions(e.target.value, id, isLocked);
        break;

      case "eap":
        editEaps(e.target.value, id, isLocked);
        break;

      default:
        break;
    }
    labelRef.current.dataset.value = e.target.value;
  };

  const textareaClass = cn("textarea", `font-${fontOption}`);

  useEffect(() => {
    const t = findParentWithClass(labelRef.current, TEXT_CLASSNAMES);
    setType(t);
  }, []);

  useEffect(() => {
    labelRef.current.dataset.value = text;
  }, [text]);


  return (
    <div className="textarea-wrap">
      <label className="input-sizer" ref={labelRef}>
        {inline ? (
          <input
            type="text"
            size="1"
            className={textareaClass}
            onChange={onTextChange}
            value={text}
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false"
            spellCheck="false"
          />
        ) : (
          <textarea
            className={textareaClass}
            onChange={onTextChange}
            value={text}
            rows="1"
            data-gramm="false"
            data-gramm_editor="false"
            data-enable-grammarly="false"
            spellCheck="false"
          />
        )}
      </label>
    </div>
  );
};

export default Textarea;
