import React, { useState } from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import { logoNorm, logoIDE, UI} from "../../../data/constants";
import { checkIfIDE } from "../../../libs/tools";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const EmailPhotoLivestream = ({ id, zoom,  }) => {
  const { layoutOption } = useSetting();
  const { bgUrlMini } = useImages();
  const templateClass = cn("template", `layout-${layoutOption}`);
  const { bgUrl } = useImages();
  const bgStyle = {
    backgroundImage: `url(${bgUrlMini})`
  };

  return (
    <>

      <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
        <div className="bg" style={bgStyle}>
          <BgTransformer src={bgUrl} id={id} />
        <div className="content">


          <div className="upload-wrap">
            <ImageUpload id={id}/>
          </div>

        </div>

        </div>
      </div>
    </>
  );
}

export default EmailPhotoLivestream;