import { embedResources } from './embed-resources'
import { toArray, isInstanceOfElement } from './util'
import { isDataUrl, resourceToDataURL } from './dataurl'
import { getMimeType } from './mimes'

async function embedProp(
  propName,
  node,
  options,
) {
  const propValue = node.style?.getPropertyValue(propName)
  if (propValue) {
    const cssString = await embedResources(propValue, null, options)
    node.style.setProperty(
      propName,
      cssString,
      node.style.getPropertyPriority(propName),
    )
    return true
  }
  return false
}

async function embedBackground(
  clonedNode,
  options,
) {
  if (!(await embedProp('background', clonedNode, options))) {
    await embedProp('background-image', clonedNode, options)
  }
  if (!(await embedProp('mask', clonedNode, options))) {
    await embedProp('mask-image', clonedNode, options)
  }
}

async function embedImageNode(
  clonedNode,
  options,
) {
  const isImageElement = isInstanceOfElement(clonedNode, HTMLImageElement)

  if (
    !(isImageElement && !isDataUrl(clonedNode.src)) &&
    !(
      isInstanceOfElement(clonedNode, SVGImageElement) &&
      !isDataUrl(clonedNode.href.baseVal)
    )
  ) {
    return
  }

  try {
    const url = isImageElement ? clonedNode.src : clonedNode.href.baseVal
    console.log('[DEBUG_LOG] Processing image:', url)

    const dataURL = await resourceToDataURL(url, getMimeType(url), options)

    // Create a timeout promise
    const timeout = options?.imageTimeout || 5000
    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error(`Image loading timeout: ${url}`)), timeout)
    })

    await Promise.race([
      new Promise((resolve, reject) => {
        // Save original attributes
        const originalSrc = isImageElement ? clonedNode.src : clonedNode.href.baseVal
        const originalSrcset = clonedNode.srcset
        const originalLoading = clonedNode.loading

        // Setup event handlers
        const handleLoad = () => {
          cleanup()
          resolve()
        }
        const handleError = (error) => {
          cleanup()
          reject(new Error(`Failed to load image: ${error.message}`))
        }

        // Cleanup function
        const cleanup = () => {
          clonedNode.onload = null
          clonedNode.onerror = null
          clonedNode.onabort = null
        }

        // Set event handlers
        clonedNode.onload = handleLoad
        clonedNode.onerror = handleError
        clonedNode.onabort = handleError

        // Force eager loading
        if (originalLoading === 'lazy') {
          clonedNode.loading = 'eager'
        }

        // Clear srcset to ensure src is used
        if (isImageElement) {
          clonedNode.srcset = ''
          clonedNode.src = dataURL
        } else {
          clonedNode.href.baseVal = dataURL
        }

        // Try to use decode API with fallback
        if (clonedNode.decode) {
          clonedNode.decode().then(handleLoad).catch(handleError)
        }

        // Fallback if image is already loaded
        if (clonedNode.complete) {
          handleLoad()
        }
      }),
      timeoutPromise
    ])

    console.log('[DEBUG_LOG] Image embedded successfully:', url)
  } catch (error) {
    console.error('[DEBUG_LOG] Failed to embed image:', error)
    throw error
  }
}

async function embedChildren(
  clonedNode,
  options,
) {
  const children = toArray(clonedNode.childNodes)
  const deferreds = children.map((child) => embedImages(child, options))
  await Promise.all(deferreds).then(() => clonedNode)
}

export async function embedImages(
  clonedNode,
  options,
) {
  if (isInstanceOfElement(clonedNode, Element)) {
    await embedBackground(clonedNode, options)
    await embedImageNode(clonedNode, options)
    await embedChildren(clonedNode, options)
  }
}
