import {DEFAULT_BACKGROUND_DATA} from "./data/backgrounds";
import {sortValuesByKey} from "./libs/tools";

export const URLS = {
  KRAKENUI: `https://resources.jetbrains.com/cai/brand-data/products.json`,
  HUANGHE: 'https://huanghe-api.labs.jb.gg',
  KRAKEN: 'https://kraken.labs.jb.gg',
}

// Cache for loaded backgrounds
const backgroundCache = new Map();

export const API = {
  getPalettes: async () => {
    const palettes = {};
    try {
      const response = await fetch(URLS.KRAKENUI);
      const data = await response.json();
      const krakenProps = data.all;
      for (const product in krakenProps) {
        const colors = krakenProps[product].palette;
        palettes[product] = colors;
      }
      return palettes;
    } catch (error) {
      console.error(error);
    }
  },

  getBackgrounds: async (channel, type, options = { progressive: false, priorityIndex: 1 }) => {
      const cacheKey = `${channel}/${type}`;
      try {
        // If we're in progressive mode and have cache, return it immediately
        if (options.progressive && backgroundCache.has(cacheKey)) {
          return backgroundCache.get(cacheKey);
        }

        const response = await fetch(`${URLS.KRAKEN}/tiles/get_tilesets?prefix=Neurobaker/Backgrounds/${channel}/${type}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json'
          }
        });
        let result = await response.json();
        const sortedBgs = sortValuesByKey(result.tile_sets);

        if (options.progressive) {
          // In progressive mode, we first return only the priority background
          const priorityBg = sortedBgs[options.priorityIndex - 1] ? [sortedBgs[options.priorityIndex - 1]] : [];
          backgroundCache.set(cacheKey, priorityBg);

          // Then load the rest of the backgrounds asynchronously
          setTimeout(async () => {
            backgroundCache.set(cacheKey, sortedBgs);
            if (options.onUpdate) {
              options.onUpdate(sortedBgs);
            }
          }, 100);

          return priorityBg;
        }

        // In non-progressive mode, return all backgrounds
        backgroundCache.set(cacheKey, sortedBgs);
        return sortedBgs;
      } catch(err) {
        console.error(err); // TypeError: failed to fetch
        return backgroundCache.get(cacheKey) || [];
      }
  },

  clearBackgroundCache: () => {
    backgroundCache.clear();
  },

  sendParams: async obj => {
    try {
      const response = await fetch(`${URLS.KRAKEN}/scene/save?prefix=neurobaker`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(obj)
      });
      let result = await response.json();
      let data = result.scene_id;
      window.location.hash = data;
      console.log('hash ',data);
      return data;
    } catch(err) {
      console.error(err); // TypeError: failed to fetch
    }
  },

  getParams: async hashStr => {
    try {
      const response = await fetch(`${URLS.KRAKEN}/scene/load?scene_id=${hashStr}&prefix=neurobaker`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      });
      return await response.json();
    } catch(err) {
      console.error(err); // TypeError: failed to fetch
    }
  },



  getTransforms: async hashStr => {
    // console.log('getting transforms', hashStr)
    if (!hashStr) {
      return DEFAULT_BACKGROUND_DATA;
    } else {
      try {
        const response = await fetch(`${URLS.KRAKEN}/scene/load?scene_id=${hashStr}&prefix=neurobaker`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          }
        });
        const result = await response.json();
        const { bgScale, bgX, bgY } = result;
        return {x: bgX, y: bgY, scale: bgScale};
      } catch(err) {
        console.error(err); // TypeError: failed to fetch
        return DEFAULT_BACKGROUND_DATA;
      }
    }
  },

  shorten: async value => {
    try {
      const obj = { "text": value }
      const response = await fetch(`${URLS.HUANGHE}/tools/shorten_text`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(obj)
      });
      const result = await response.json();
      return result.shortened_text;
    } catch(err) {
      console.error(err); // TypeError: failed to fetch
    }
  },

  translate: async (value, target_language) => {
    try {
      const obj = {
        "text": value,
        "target_language": target_language
      }
      const response = await fetch(`${URLS.HUANGHE}/tools/translate_text`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(obj)
      });
      const result = await response.json();
      return result.translated_text;
    } catch (err) {
      console.error(err);
    }
  },

}
