import React from "react";
import {useSetting} from "../../contexts/setting";
import {EYE_FALSE, EYE_TRUE, LOCK_FALSE, LOCK_TRUE} from "../../data/constants";
import cn from "classnames";
import './index.css';


const TemplateControls = ({ id, zoom, }) => {
  const {editVisible, editLocked, visible, locked} = useSetting();
  const isVisible = visible.some(x => x === id);
  const isLocked = locked.some(x => x === id);
  const lockClass = cn('lock checkbox', { "locked": isLocked });
  const visibleClass = cn('eye checkbox', { "visible": isVisible });
  const eyeTitle = isVisible ? "Hide" : "Show";
  const lockTitle = isLocked ? "Unlock" : "Lock";
  return (
    <div className="template-controls">
      <input
        className="template-checkbox"
        type="checkbox"
        id={`visibility-${id}`}
        checked={isVisible}
        onChange={() => editVisible(id, !isVisible)}
      />
      <label title={eyeTitle} htmlFor={`visibility-${id}`} className={visibleClass}>
        {isVisible ? <img src={EYE_TRUE} alt=""/> : <img src={EYE_FALSE} alt=""/>}
      </label>
      <input
        className="template-checkbox"
        type="checkbox"
        id={`lock-${id}`}
        checked={isLocked}
        onChange={() => editLocked(id, !isLocked)}
      />
      <label title={lockTitle} htmlFor={`lock-${id}`} className={lockClass}>
        {isLocked ? <img src={LOCK_TRUE} alt=""/> : <img src={LOCK_FALSE} alt=""/>}
      </label>
    </div>
  )}

export default TemplateControls;