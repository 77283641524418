import JSZip from "jszip";
import {saveAs} from "file-saver";
import {toPng} from "./html2picture";
import {
  BACKGROUNDS,
  IDEs,
  logoAcademyBlack,
  logoAcademyNorm,
  logoIDE,
  logoIDEBlack,
  logoIDEWhite,
  logoNorm,
  logoNormInverted,
  logoNormBlack,
  logoNormBlackInverted,
  logoYouTrackBlack
} from "../data/constants";


export const getResizedImageBase64 = async (imageURL, width = 180) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = 'Anonymous';
    image.src = imageURL;

    image.onload = () => {
      const aspectRatio = image.height / image.width;
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = width;
      canvas.height = width * aspectRatio;

      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      const base64Image = canvas.toDataURL('image/png');
      resolve(base64Image);
    };

    image.onerror = () => {
      reject(new Error('Error on image load'));
    };
  });
};

export const exportLayouts = async (refs, product, channel) => {
  // const dropzones = document.querySelectorAll(".image--drop-zone");
  // if (dropzones.length > 0) [...dropzones].forEach(element => { element.classList.add("invisible") })

  const imgObjects = refs.map( async current => {

    const id = current.id;
    const html = current.children[0];

    console.log(html)


    const b64 = await toPng(html)
      .then(function (dataUrl) {
        var img = new Image();
        img.src = dataUrl;
        img.crossOrigin = "anonymous";
        return img;
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });



    const w = b64.width;
    const h = b64.height;
    const ratio = window.devicePixelRatio;

    const actualW = w / ratio;
    const actualH = h / ratio;

    const image = document.createElement('canvas');
    image.width = actualW;
    image.height = actualH;
    const ctx = image.getContext('2d');
    ctx.drawImage(b64, 0,0, actualW, actualH);
    const url = image.toDataURL();

    return { url, w: actualW, h: actualH, id }
  })


  return Promise.all(imgObjects)
    .then( array => {
      const zip = new JSZip();

      array.forEach((obj, i) => {
        // const name =  Object.keys(refs)[i];
        const name =  `${product}-${channel}-${obj.id}-${obj.w}x${obj.h}`;
        zip.file(
          `${name}.png`,
          obj.url.substring("data:image/png;base64,".length),
          {base64: true}
        );
      })

      return zip.generateAsync({type: "blob"});
    })
    .then(result => {
      const name = `${product}-${channel}`;
      saveAs(result, name);
    })
    .catch((e) => console.log('Error downloading images', e));
}

export const isEmptyObj = obj => {
  if (!obj) return;
  const isEmpty = Object.keys(obj).length === 0;
  return isEmpty;
}

export const changeFontSize = (elems, coef, styleEl) => {
  elems.forEach(div => {
    const currentSize = getComputedStyle(div).fontSize;
    const fz = `calc(${coef}*${currentSize})`;
    const classname = `font-size${(Math.random() * 1000000).toFixed()}`;
    const oldFontClasses = [...div.classList].filter(cl => (cl.includes("font-size")));
    if (oldFontClasses.length > 0) div.classList.remove(oldFontClasses);
    div.classList.add(classname);
    styleEl.sheet.insertRule(`.${classname} {font-size:${fz}!important}`);
  })
}

export const getImageOrientation = image =>
  new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      resolve(img.width > img.height ? "horiz" : "vert");
    };

    img.onerror = reject;

    img.src = image.data_url;
  });


export const checkIfIDE = (product, type) => {
  // Check if it's a PHP Annotated newsletter
  if (product === "PS" && type === "php-annotated") {
    return false;  // Use normal logo for PHP Annotated
  }
  // For all other cases, check if it's an IDE product
  return IDEs.some(ide => product === ide);
};

export const findParentWithClass = (element, classNames) => {
  let currentElement = element;

  // Traverse up the DOM tree
  while (currentElement.parentElement) {
    currentElement = currentElement.parentElement;

    // Check if the current parent element has any of the class names from the array
    for (let className of classNames) {
      if (currentElement.classList.contains(className)) {
        return className;
      }
    }
  }

  // Return null if no parent with any of the specified class names is found
  return '';
}


export const checkImageLoaded = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = function() {
      resolve(true);
    };

    img.onerror = function() {
      // console.log(url)
      resolve(false); // or you can use reject(new Error('Image failed to load')) if you prefer
    };

    img.src = url;
  });
};


export const getHashes = arraysOfArrays => {
    const result = {};
    const exclusions = {};

    // console.log('arraysOfArrays', arraysOfArrays)
    arraysOfArrays.forEach((subArray, outerIndex) => {
      for (let innerIndex = 0; innerIndex < subArray.length; innerIndex++) {
        if (subArray[innerIndex].includes('/#')) {
          result[outerIndex+1] = extractHash(subArray[innerIndex], "#");
        }
      }
    });
    return Object.keys(result).length ? result : {};
    // return {hashes, exclusions};
  }



  const extractHash = (string, symbol) => {
    const hashIndex = string.indexOf(symbol);

    if (hashIndex === -1) {
      return '';
    }

    const dotIndex = string.indexOf('.', hashIndex);

    if (dotIndex === -1) {
      return string.substring(hashIndex + 1);
    }

    return string.substring(hashIndex + 1, dotIndex);
  }


  export const getBgUrl = (channel, type) => {
    if (type) {
      console.log(channel, type)
      return `${BACKGROUNDS}/${channel}/${type}`;
    } else {
      console.log(channel, type)
      return `${BACKGROUNDS}/${channel}`;
    }
  }

export const arrayToObject = arr => {
  const result = {};
  arr.forEach((subArray, index) => {
    const key = (index + 1).toString();
    result[key] = subArray;
  })
  return result;
}


function escapeRegExp(str) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export const findBgUrl = (arr, index, partialSubstring) => {
  // console.log('searching bg', arr, index, partialSubstring);
  if (!arr[index - 1]) return undefined;

  console.log('partialSubstring', partialSubstring)
  const productValueMatch = partialSubstring.match(/\/([^/]+)$/);
  let pattern;

  if (productValueMatch) {
    const matchValue = productValueMatch[1]; 

    if (!matchValue.includes('@') && !matchValue.includes('-')) {
      const escaped = escapeRegExp(partialSubstring);
      pattern = new RegExp(`${escaped}(?!@|-)`, 'i');
    } else {
      const escaped = escapeRegExp(partialSubstring);
      pattern = new RegExp(escaped, 'i');
    }
  } else {
    const escaped = escapeRegExp(partialSubstring);
    pattern = new RegExp(escaped, 'i');
  }

  return arr[index - 1].find((url) => pattern.test(url) && url.endsWith('.png'));
};

export const getProductHash = url => {
  if (url?.includes('&')) {
    return extractHash(url, "&");
  } else return null;
}

export function getLogoUrl(productValue, bgUrl, type) {
  if (productValue === "EDU") {
    if (bgUrl && bgUrl.includes("_blacklogo")) {
      return logoAcademyBlack;
    }
    return logoAcademyNorm;
  }
    if (productValue === "YT") {
        if (bgUrl && bgUrl.includes("_blacklogo") && bgUrl.includes("_productlogo")) {
            return logoYouTrackBlack;
        }
    }
  if (productValue === "IH") {
    if (bgUrl && bgUrl.includes("_whitelogo_inverted")) {
      return logoNormInverted;
    }
    if (bgUrl && bgUrl.includes("_whitelogo")) {
      return logoNorm;
    }
    return logoNormBlack;
  }

  if (productValue === "AS") {
    if (bgUrl && bgUrl.includes("_whitelogo_inverted")) {
      return logoNormInverted;
    }
    return logoNorm;
  }

  const isIDE = checkIfIDE(productValue, type);
  if (bgUrl && bgUrl.includes("_whitelogo")) {
    return isIDE ? logoIDEWhite : logoNorm;
  }
  if (bgUrl && bgUrl.includes("_blacklogo")) {
    return isIDE ? logoIDEBlack : logoNormBlack;
  }
  return isIDE ? logoIDE : logoNorm;
}

export const sortValuesByKey = (object) => {
  const keys = Object.keys(object);

  keys.sort((a, b) => {
    const numA = parseInt(a.match(/\d+$/)[0], 10);
    const numB = parseInt(b.match(/\d+$/)[0], 10);
    return numA - numB;
  });

  return keys.map(key => object[key]);
}

export const getThumbnailUrl = (inputString) => {
  const TARGET_FOLDER = 'tiles';
  const REPLACEMENT_FOLDER = 'gdrive_thumbnails';
  const TARGET_EXTENSION = /\.png$/;
  const REPLACEMENT_EXTENSION = '.webp';

  return inputString
    .replace(TARGET_FOLDER, REPLACEMENT_FOLDER)
    .replace(TARGET_EXTENSION, REPLACEMENT_EXTENSION);
}
