import React, {useState} from "react";
import "./index.css";
import cn from "classnames";
import Textarea from "../../../layout/Textarea/Textarea";
import ImageUpload from "../../../layout/ImageUpload/index";
import { logoNorm, logoIDE, UI, PLACEHOLDERS} from "../../../data/constants";
import { checkIfIDE } from "../../../libs/tools";
import Icon from "../../../layout/Icon";
import BgTransformer from "../../../layout/BgTransformer";
import {useSetting} from "../../../contexts/setting";
import {useEdits} from "../../../contexts/edits";
import {useImages} from "../../../contexts/images";

const Email03 = ({ id, zoom, }) => {
    const { layoutOption } = useSetting();
    const {titles, names, badges, } = useEdits();
    const [isInline, setIsInline] = useState(true);

    const {bgUrl} = useImages();
    const title = titles[id] || titles.main;
    const badge = badges[id] || badges.main;
    const name = names[id] || names.main || PLACEHOLDERS.en.emailName;

    const templateClass = cn("template", `layout-${layoutOption}`);

  return (
    <>

      <div id={id} className={templateClass} style={{"--zoom-level": zoom}}>
        <div>
          <BgTransformer src={bgUrl} id={id} />

          <div className="content" >

            <div className="title-wrap">
              <h1 className="title">
                    <Textarea id={id} text={title}/>
              </h1>
            </div>
              {+layoutOption === 3 && (
                  <div className="badge">
                      <Textarea id={id} text={badge} inline={isInline}/>
                  </div>
              )}
              {+layoutOption === 4 && (
                  <div className="name">
                      <Textarea id={id} text={name} />
                  </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Email03;
